
import { defineComponent, computed, ref, onMounted, inject, onBeforeUnmount } from 'vue';
import router from '@/router';
import { api } from '@/services/Api';
import Q from 'q';
import { generalStore } from '@/store';
import swal from 'sweetalert2';
import { BusinessClientsFilter, BusinessClient } from '@/models/BusinessClients';

export default defineComponent({
    components: {},
    name: 'BusinessClients',
    data() {
        return {
            doSearch: false
        };
    },
    async setup() {
        const filter = ref<BusinessClientsFilter>(new BusinessClientsFilter());
        filter.value = generalStore.getters.getBusinessClientsFilter;
        const localCurrencySymbol = generalStore.state.localCurrencySymbol;
        const businessClients = ref<BusinessClient[]>([]);
        const busy = ref(false);
        const loaded = ref(false);
        const hasNext = ref(false);
        const total = ref(0);
        const totalFiltered = ref(0);
        const emitter: any = inject('emitter');
        const hasFilter = computed<boolean>(() => {
            if (filter.value.id) {
                return true;
            }
            if (filter.value.name) {
                return true;
            }
            if (filter.value.type) {
                return true;
            }
            return false;
        });

        const ShowFilter = () => {
            generalStore.commit('updateBusinessClientsFilter', filter.value);
            router.push({ name: 'modal-business-clients-filter' });
        };

        function goToDetails(businessClientId: number) {
            router.push({ name: 'businessClientView', params: { id: businessClientId, page: 1 } });
        }

        function newBusinessClient() {
            router.push({ name: 'businessClientNew' });
        }

        const GetClientsApiCall = async () => {
            swal.fire({
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    swal.showLoading();
                },
                showConfirmButton: false
            });
            busy.value = true;
            const apiResult = await api.GetBusinessClients(filter.value);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            hasNext.value = apiResult.data?.hasNext || false;
            if (filter.value.pageIndex == 0) {
                total.value = apiResult.data?.total || 0;
                totalFiltered.value = apiResult.data?.totalFiltered || 0;
            }
            if (apiResult.data && businessClients.value && businessClients.value.length) {
                businessClients.value = [...businessClients.value, ...apiResult.data.rows];
            } else {
                businessClients.value = apiResult.data?.rows || [];
            }
            filter.value.pageIndex++;
            busy.value = false;
            swal.close();
        };

        async function GetClients() {
            businessClients.value = [];
            filter.value.pageIndex = 0;
            await GetClientsApiCall();
        }

        function ListenerGetclients() {
            if (hasNext.value && !busy.value) {
                GetClientsApiCall();
            }
        }

        async function sotrBy(sotrBy: string) {
            if (filter.value.orderBy != sotrBy) {
                filter.value.asc = true;
            } else {
                filter.value.asc = !filter.value.asc;
            }
            filter.value.orderBy = sotrBy;
            filter.value.pageIndex = 0;
            businessClients.value = [];
            await GetClientsApiCall();
        }

        function getArrowClass(sort: string) {
            if (sort == filter.value.orderBy) {
                if (filter.value.asc) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        }

        async function showAll() {
            generalStore.commit('updateCatalogFilter', new BusinessClientsFilter());
            filter.value = new BusinessClientsFilter();
            businessClients.value = [];
            await GetClientsApiCall();
        }
        function onBeforeUnMountedCall() {
            emitter.off('isBottom', ListenerGetclients);
        }
        const onMountedCall = async () => {
            emitter.on('isBottom', ListenerGetclients);
            filter.value.pageIndex = 0;
            await GetClientsApiCall();
            loaded.value = true;
        };

        onMounted(onMountedCall);
        onBeforeUnmount(onBeforeUnMountedCall);

        return {
            ShowFilter,
            hasFilter,
            goToDetails,
            businessClients,
            total,
            totalFiltered,
            showAll,
            loaded,
            sotrBy,
            getArrowClass,
            newBusinessClient,
            filter,
            GetClientsApiCall,
            GetClients,
            localCurrencySymbol
        };
    }
});
